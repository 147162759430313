<template>
    <div class="footer">
      <div class="container py-4">
        <div class="row g-5">
          <div class="col-lg-3 col-md-6">
            <h1 class="fw-bold mb-3" style="color:var(--primary-color);">EXECUTORI<span class="text-white">.ro</span></h1>
            <p>Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică,
              formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu
              organizare, funcţionare şi conducere autonome.</p>
          </div>
          <div class="col-lg-3 col-md-6">
            <h5 class="mb-3">Contact</h5>
            <span><i class="bi bi-geo-alt-fill px-1"></i>Str. Cuza Voda, Nr. 143, Sectorul 4, Bucuresti</span><br/>
            <span><i class="bi bi-telephone-fill px-1"></i>021.320.50.92</span><br/>
            <span><i class="bi bi-envelope-fill px-1"></i>uniune@executori.ro</span>
          </div>
          <div class="col-lg-3 col-md-6">
            <h5 class="mb-3">Linkuri rapide</h5>
            <router-link class="btn-link" to="/contact"><i
                class="bi bi-chevron-compact-right"></i>
              Contact</router-link>
            <a class="btn-link" href="https://unejr.ro" title="repes"><i class="bi bi-chevron-compact-right"></i> Repes</a>
            <router-link class="btn-link" to="/informatii-generale/despre"><i
                class="bi bi-chevron-compact-right"></i>
              Despre
              UNEJ</router-link>
            <router-link class="btn-link" to="/tabloul-executorilor"><i
                class="bi bi-chevron-compact-right"></i> Tabloul executorilor</router-link>
            <router-link class="btn-link" to="/harta-executorilor"><i
                class="bi bi-chevron-compact-right"></i> Harta executorilor</router-link>
            <router-link class="btn-link" to="/international"><i
                class="bi bi-chevron-compact-right"></i> Internațional</router-link>
            <router-link class="btn-link" to="/informatii-generale/conducere"><i
                class="bi bi-chevron-compact-right"></i> Conducere</router-link>
          </div>
          <div class="col-lg-3 col-md-6">
            <h5 class="mb-3">Acces membri</h5>
            <a class="btn-link" href="https://executori.ro/membri/" target="_blank"><i
                class="bi bi-box-arrow-in-right"></i> Logare Membri</a>
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
              {{ new Date().getFullYear() }} &#169; Uniunea Naţională a Executorilor Judecătoreşti
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <style scoped>
  .footer {
    background-color: var(--background-color-footer);
    color: #ddd;
  }
  .footer .btn-link {
    display: block;
    padding: 0;
    text-align: left;
    color: #ddd;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
    text-decoration: none;
  }
  
  .footer .btn-link:hover {
    text-decoration: none;
    color: #296CA6;
    transition: 0.3s;
    margin-left: 10px;
  }
  
  
  .footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
  }
  </style>