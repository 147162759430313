<template>
  <div class="bg">
    <Navbar />
    <router-view>
    </router-view>
    <Footer />
  </div>
  <VerticalButton />
  <ScrollToTop />


  <!-- cookie banner -->
  <div class="cookie-banner" v-if="showMessage">
    <small>
      Acest website folosește cookie-uri esențiale. Acestea
      reprezintă
      cookie-urile care sunt necesare pentru ca website-ul să funcționeze în
      mod corespunzător.</small> <button class="btnCo" @click="myCookies">Am înțeles</button>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import ScrollToTop from "@/components/ScrollToTop.vue";
import VerticalButton from "@/components/VerticalButton.vue";
import Footer from "@/components/Footer.vue";



export default {
  name: "App",
  data() {
    return {
      showMessage: false,
      consent: false,
    }
  },
  components: {
    Navbar,
    Footer,
    ScrollToTop,
    VerticalButton,
  },
  methods: {
    myCookies() {
      this.$cookies.set("consent", "accepted", "365d");
      this.showMessage = false;
    },
  },
  mounted() {
    if (!this.$cookies.get("consent")) {
      this.consent = false;
      this.showMessage = true;
    } else {
      this.consent = true;
      this.showMessage = false;
    };
    // Apply the saved dark mode setting from localStorage when the app loads
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    if (isDarkMode) {
      document.body.classList.add('dark-theme');
    }
  },
};
</script>


<style scoped>
/* cookie banner */
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999999;
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 20px;
  margin: 10px;
  margin-bottom: 40px;
  height: 230px;
  width: 250px;
  border-radius: 10px;
}

.cookie-banner button {
  background-color: #5384af;
  color: white;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  padding: 5px 10px;
}

.cookie-banner button:hover {
  background-color: #90b7d8;
  transition: .3s;
}
</style>