<template>
  <div>
    <input type="checkbox" id="toggle" @change="toggleDarkMode" :checked="isDarkMode">
    <label id="switch" for="toggle">
      <div id="circle">
        <!-- Light Mode Icon -->
        <svg v-if="!isDarkMode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#333"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
          <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
        </svg>
        <!-- Dark Mode Icon -->
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ff8100" stroke="#ff8100"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
          <circle cx="12" cy="12" r="5"></circle>
          <path d="M12 1v2"></path>
          <path d="M12 21v2"></path>
          <path d="M4.22 4.22l1.42 1.42"></path>
          <path d="M18.36 18.36l1.42 1.42"></path>
          <path d="M1 12h2"></path>
          <path d="M21 12h2"></path>
          <path d="M4.22 19.78l1.42-1.42"></path>
          <path d="M18.36 5.64l1.42-1.42"></path>
        </svg>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDarkMode: false
    };
  },
  mounted() {
    // Check user's preferences from local storage or system settings
    this.isDarkMode =
      localStorage.getItem('darkMode') === 'true' ||
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.applyDarkMode();

    // Listen for system preference changes
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      this.isDarkMode = event.matches;
      this.applyDarkMode();
    });
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      localStorage.setItem('darkMode', this.isDarkMode);
      this.applyDarkMode();
    },
    applyDarkMode() {
      if (this.isDarkMode) {
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    }
  }
};
</script>

<style scoped>

input[type="checkbox"] {
  display: none;
}

#switch {
  /* margin: 0 auto; */
  position: relative;
  display: block;
  width: 55px;
  height: 30px; /* Adjusted height for the switch */
  background-color: rgb(221, 221, 221);
  border-radius: 350px;
  cursor: pointer;
  transition: background 0.4s;
}

#circle {
  width: 30px; /* Adjusted width for the circle */
  height: 30px; /* Adjusted height for the circle */
  background-color: var(--background-color-secondary);
  position: relative;
  top: 0; /* Centered vertically */
  left: 0; /* Centered horizontally */
  border: 6px solid var(--background-color-secondary);
  border-radius: 50%; /* Circle shape */
  transition: 0.4s ease left;
  z-index: 2;
  display: flex; /* Flexbox to center icons */
  justify-content: center; /* Center the icon */
  align-items: center; /* Center the icon */
}

.icon {
  width: 25px; /* Icon size */
  height: 25px; /* Icon size */
  transition: opacity 0.4s ease;
}

#toggle:checked + #switch #circle {
  left: calc(100% - 30px); /* Move circle to the right */
}

#toggle:checked + #switch {
  background: #212121; /* Dark mode background */
}

/* Styles for dark mode */
.dark-theme {
  background-color: #121212; /* Dark mode background */
  color: #ffffff; /* Dark mode text color */
}
</style>
