<template>
    <div class="verticalB">
        <a class="vertical-btn" href="https://www.executori.ro/membri"
        data-tooltip="Sectiunea membri" target="_blank">MEMBRI <i class="bi bi-people-fill"></i></a>
    </div>
  </template>
  
  <style lang="scss" scoped>
  .vertical-btn {
    background: var(--primary-color);
    border-radius: 0;
    color: white;
    display: inline-block;
    font-size: 1rem;
    height: 30px;
    line-height: 30px;
    position: fixed;
    right: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transform-origin: bottom right;
    width: 120px;
    top: 400px;
    z-index: 1;
    &:hover {
      background-color: #296ca6c5;
    }
  }
  [data-tooltip]::before {
      content: attr(data-tooltip);
      position: absolute;
      opacity: 0;
      transition: all 0.15s ease;
      width: 200px;
      font-size: 12px;
      color: #333;
      border-radius: 10px;
      box-shadow: 2px 2px 1px silver;
      transform: rotate(90deg);
  }
  
  [data-tooltip]:hover::before {
      opacity: 1;
      background: whitesmoke;
      margin-top: -110px;
      margin-left: 20px;
  }
  
  [data-tooltip]:not([data-tooltip-persistent])::before {
      pointer-events: none;
  }
  
  @media only screen and (max-width: 800px) {
    .verticalB {
      display: none;
    }
  }
  </style>
  
  
  <!-- <script>
  export default {
    methods: {
      openLink() {
        window.open("https://www.executori.ro/membri/#/login", "_blank");
      },
    }
  }
  </script> -->