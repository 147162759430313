<template>
    <div class="header-title">
        <div class="container">
            <h1 class="animate fadeInDown text-center">{{ $route.name }}</h1>
            <span class="text-white d-flex justify-content-center">
                <router-link class="link" to='/'><i class="bi bi-house px-2"></i>Acasă</router-link> <span
                    class="text-white px-2">/</span>
                <router-link class="link" :to=$route.path>{{ $route.name }}</router-link>
            </span>
        </div>
    </div>
</template>

<style scoped>
.header-title {
    position: relative;
    height: 300px;
    margin-top: -30px;
    color: white;
    display: flex;
    align-items: center;
    z-index: 1; /* Ensure content stays on top */
}

.header-title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: var(--shadow-header), url(https://www.executori.ro/api/public/static/img/ro.jpg);
    background-repeat: round;
    background-size: cover;
    filter: blur(2px); /* Apply blur filter to background */
    z-index: -1; /* Make sure the background stays behind the content */
}


.header-title .link {
    color: white;
    text-decoration: none;
}

.header-title a.router-link-exact-active {
    color: white;
}
</style>