<template>
  <button v-if="isVisible" @click="scrollToTop" class="scroll-to-top">
    <i class="bi bi-chevron-compact-up"></i>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false, // Inițial butonul este ascuns
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      this.isVisible = window.scrollY > 300; // Afișează butonul dacă scroll-ul depășește 300px
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll); // Ascultă evenimentul de scroll
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Oprește ascultarea când componenta este distrusă
  },
};
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.scroll-to-top:hover {
  background-color: #2980b9;
}
</style>
