<template>
    <div class="gallery">
        <!-- Display first two images -->
        <div v-for="(image, index) in displayedImages" :key="index" class="image-container" @click="openOverlay(index)">
            <img :src="image" :alt="'Image ' + (index + 1)" class="gallery-image" />
        </div>

        <!-- Placeholder for remaining images -->
        <div v-if="remainingImagesCount > 0" class="image-container remaining-images" @click="openFullGallery">
            <span class="remaining-count">+{{ remainingImagesCount }}</span>
        </div>

        <!-- Overlay Section -->
        <div v-if="isOverlayOpen" class="overlay">
            <span class="close-btn" @click="closeOverlay"><i class="bi bi-x-lg"></i></span>
            <button class="nav-btn prev-btn" @click="prevImage" :disabled="currentImage <= 0">
                <i class="bi bi-chevron-compact-left"></i>
            </button>
            <img :src="gallery[currentImage]" class="overlay-image" />
            <button class="nav-btn next-btn" @click="nextImage" :disabled="currentImage >= gallery.length - 1">
                <i class="bi bi-chevron-compact-right"></i>
            </button>
        </div>

        <!-- Full Gallery Popup -->
        <div v-if="isFullGalleryOpen" class="full-gallery-popup">
            <span class="close-btn" style="color: var(--text-color);" @click="closeFullGallery"><i class="bi bi-x-lg"></i></span>
            <div class="full-gallery mt-5 row g-1"> <!-- Bootstrap row with gutter -->
                <div v-for="(image, index) in gallery" :key="index" class="col-6 col-md-4" @click="openOverlay(index)">
                    <img :src="image" :alt="'Image ' + (index + 1)" class="full-gallery-image w-100" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        gallery: {
            type: Array,
            required: true
        },
        galleryId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            currentImage: null,
            isOverlayOpen: false,
            isFullGalleryOpen: false
        };
    },
    computed: {
        displayedImages() {
            return this.gallery.slice(0, 2); // Display only the first two images
        },
        remainingImagesCount() {
            return this.gallery.length > 2 ? this.gallery.length - 2 : 0; // Calculate remaining images
        }
    },
    methods: {
        openOverlay(index) {
            this.currentImage = index; // Set current image
            this.isOverlayOpen = true; // Open overlay
        },
        closeOverlay() {
            this.isOverlayOpen = false; // Close overlay
        },
        prevImage() {
            if (this.currentImage > 0) {
                this.currentImage--; // Go to previous image
            }
        },
        nextImage() {
            if (this.currentImage < this.gallery.length - 1) {
                this.currentImage++; // Go to next image
            }
        },
        openFullGallery() {
            this.isFullGalleryOpen = true; // Open full gallery
        },
        closeFullGallery() {
            this.isFullGalleryOpen = false; // Close full gallery
        }
    }
};
</script>

<style scoped>
.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
}

.image-container {
    position: relative;
    cursor: pointer;
}

.gallery-image {
    width: 150px;
    height: 100px;
    object-fit: cover;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000001;
}

.overlay-image {
    max-width: 80%;
    max-height: 80%;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    cursor: pointer;
    font-size: 30px;
}

.remaining-images {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 100px;
    background-color: #333;
    color: white;
    font-size: 24px;
}

.remaining-count {
    font-size: 40px;
}

.nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
}

.prev-btn {
    left: 10px;
}

.next-btn {
    right: 10px;
}

.nav-btn:disabled {
    background-color: #666;
    cursor: not-allowed;
}

/* Full Gallery Popup Styles */
.full-gallery-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    height: 80%;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1000000;
    padding: 20px;
    box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    overflow-y: auto; /* Allow scrolling if content is taller than the viewport */
}

.full-gallery-image {
    height: 150px; /* Fixed height for images */
    object-fit: cover; /* Maintain aspect ratio */
    cursor: pointer;
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .full-gallery-image-container {
        width: 130px;
        /* Half the width for medium screens */
        height: 50x;
        /* Adjust height automatically to maintain aspect ratio */
    }

    .gallery-image {
        width: 100px;
        height: 70px;
    }

    .remaining-images {
        width: 100px;
        height: 70px;
    }
}

@media (max-width: 480px) {
    .full-gallery-image-container {
        width: 130px;
        /* Half the width for medium screens */
        height: 50x;
        /* Adjust height automatically to maintain aspect ratio */
    }

    .gallery-image {
        width: 100px;
        height: 70px;
    }

    .remaining-images {
        width: 100px;
        height: 70px;
    }
}


</style>


