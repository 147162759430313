<template>
    <Spinner v-if="loading" />

    <div v-else>
        <NationalDay v-if="nationalDay" /> <!--1december-->

        <Announcements />

        <div class="container">
            <div class="row">

                <div class="col-lg-3">
                    <SideBar />
                </div>

                <div class="col-lg-9">
                    <div class="mt-3" v-for="item in items" :key="item.id" :id="item.id">
                        <div class="card">
                            <div class="row">
                                <div class="px-7">
                                    <div class="card-block px-3">
                                        <div class="data d-flex justify-content-center"><i
                                                class="bi bi-calendar px-1"></i>Publicat la:
                                            {{ item.data.slice(0, 10) }} {{ item.data.slice(11, 19) }}</div>
                                        <h4 class="my-card-title py-3 mt-3">{{ item.title }}</h4>
                                        <div class="line" :style="{ 'background-color': `${item.importance}` }"></div>
                                        <div class="card-text">

                                            <div style="font-size: small;" v-html="item.html" />
                                            <div style="font-size: small; color: red; text-align: center;"
                                                v-if="item.hasEllipse">(Click
                                                pe
                                                articol pentru
                                                expandare/restrângere)</div>
                                            <div style="font-size: small;" v-html="item.ellipse" v-if="item.hasEllipse"
                                                @click="toggleEllipsis"></div>
                                            <div style="font-size: small;" v-html="item.html2" v-if="item.hasContent2">
                                            </div>
                                            <img class="item_image" :src=item.image :alt="executor" />
                                            <p v-if="item.caption" class="text-center">
                                                {{ item.caption }}
                                            </p>
                                            <div class="MyGallery mb-3 mt-3">
                                                <MyGallery v-if="item.gallery && item.gallery.length"
                                                    :gallery="item.gallery" />
                                            </div>

                                        </div>

                                        <div class="mb-3" v-for="item in item.links" :key="item.name">
                                            <a class="card-link" :href=item.link target="_blank">{{ item.name }}</a>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <RSS /> <!--Juridice news-->
</template>

<script>
import NationalDay from "../components/NationalDay.vue"
import RSS from "../components/RSS.vue"
import SideBar from "../components/SideBar.vue"
import MyGallery from "../components/Gallery.vue"
import Announcements from "@/components/Announcements.vue"
import axios from 'axios'

export default {
    data() {
        return {
            rss: [],
            items: [],
            loading: true,
            errorMsg: "",
            index: null,
            allNews: 0,
            pageSize: 10,
            page: 0,
            previousScroll: 0,
            nationalDay: false,
        }
    },
    components: {
        MyGallery,
        SideBar,
        Announcements,
        RSS,
        NationalDay,
    },
    methods: {
        handleScroll() {
            if (window.scrollY - this.previousScroll > 200 && !this.finished) {
                this.previousScroll = window.scrollY;
                this.page = this.page + 1;
                if (this.page >= this.allNews / 10) {
                    this.finished = true;
                } else {
                    this.getMoreContent(this.pageSize * this.page, this.pageSize);
                }
            }
        },

        async getMoreContent(offset, limit) {
            axios
                .get(
                    `https://www.executori.ro/api/public/getNewsData?offset=${offset}&limit=${limit}`
                )
                .then((response) => {
                    this.allNews = response.data.allNews.allNews;
                    this.items = this.items.concat(response.data.news);
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => this.loading = false)

        },
        toggleEllipsis() {
            var element = document.getElementById("ellipsis-ex");
            element.classList.toggle("less-text");
        },

    },
    mounted() {
        this.getMoreContent(0, 10);
        window.addEventListener("scroll", this.handleScroll);
    },
    created() {
        let month = new Date().getMonth();
        let day = new Date().getDate();

        if (month == 11 && day == 1) {
            return this.nationalDay = true;
        };
    }
}
</script>

<style scoped lang="scss">
.my-card-title {
    font-size: 20px;
    margin-bottom: 0;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--text-color);
}

.line {
    height: 5px;
    width: 150px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.card:hover .line {
    height: 5px;
    width: 300px;
    transition: .3s;
}

.card-text {
    color: var(--text-color);
    margin-bottom: 10px;
    font-size: small;
}

.item_image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
}

.data {
    position: absolute;
    top: 0;
    right: 30px;
    background-color: var(--primary-color);
    color: white;
    letter-spacing: 1px;
    font-size: small;
    width: 270px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 20px;
}

@media only screen and (max-width : 576px) {
    .data {
        position: absolute;
        top: 0;
        right: 10px;
    }
}

.card-link {
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
    width: auto;
    position: relative;
    background-color: transparent;
    text-decoration: none;
    color: rgb(14, 111, 255)!important;
    padding: 2px;
    font-weight: 500;
    &::after {
        content: "→";
        text-decoration: none;
        font-weight: bold;
        position: absolute;
        left: 100%;
        opacity: 0;
    }
    &:hover {
        transition: all 0.3s;
        border-bottom: 1px solid rgb(14, 111, 255);
        padding: 2px 10px 2px 2px;

        &::after {
            opacity: 1;
            transition: all 0.3s;

        }
    }
}
</style>